<template>
  <div>
    <div class="modal fade" id="mediaModal" tabindex="-1" role="dialog" aria-labelledby="mediaModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="mediaModalLabel">
              Thư viện media
            </h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-2">
                <div class="list-group list-group-flush">
                  <a href="javascript:void(0)" v-on:click="filterMediaByType('')" :class="getItemClasses('')">Tất cả</a>
                  <a href="javascript:void(0)" v-on:click="filterMediaByType('image')" :class="getItemClasses('image')">Hình
                    ảnh</a>
                  <a href="javascript:void(0)" v-on:click="filterMediaByType('video')" :class="getItemClasses('video')">Video</a>
                  <a href="javascript:void(0)" v-on:click="filterMediaByType('audio')" :class="getItemClasses('audio')">Âm thanh</a>
                  <a href="javascript:void(0)" v-on:click="filterMediaByType('other')" :class="getItemClasses('other')">Tài
                    liệu</a>
                </div>
                <div class="mt-4">
                  <Upload v-on:uploadFinished="handleUploadFinished"/>
                </div>
              </div>
              <div class="col-md-10">
                <div class="row">
                  <div class="col-md-9">
                    <div class="row" id="media">
                      <div class="col-md-2 media-item mb-4" v-for="media in media.data" :key="'media' + media.id"
                           v-on:click="setCurrentMedia(media)">
                        <img :src="media.link" class="rounded-3" :alt="media.name" v-if="media.media_type === 'image'">

                        <div class="file-wrap" v-if="media.media_type === 'other' || media.media_type === 'video' || media.media_type === 'audio'">
                          <i class="mdi mdi-file-pdf" v-if="media.extension === 'pdf'"></i>
                          <i class="fe fe-file" v-if="media.extension !== 'pdf' && media.media_type !== 'video' && media.media_type !== 'audio'"></i>
                          <i class="fe fe-music" v-if="media.media_type === 'audio'"></i>
                          <i class="fe fe-video" v-if="media.media_type === 'video'"></i>
                        </div>

                        <small>{{ cutString(media.name, 10) }}</small>
                      </div>

                      <v-pagination
                          v-if="media.paginatorInfo.lastPage > 1"
                          v-model="media.paginatorInfo.currentPage"
                          :pages="media.paginatorInfo.lastPage"
                          :range-size="1"
                          active-color="#DCEDFF"
                          @update:modelValue="loadMedia"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <form class="d-flex align-items-center">
                                    <span class="position-absolute ps-3 search-icon">
                      <i class="fe fe-search"></i>
                    </span>
                      <input v-model="mediaFilters.name" type="search" class="form-control ps-6" placeholder="Tìm media">
                    </form>
                    <div v-if="currentMedia" class="mt-3">
                      <div class="media-detail">
                        <div class="player-wrap-tiny">
                          <player
                              v-if="currentMedia.media_type === 'video' || currentMedia.media_type === 'audio'"
                              :type="currentMedia.media_type"
                              :options="getPlayerOptions(currentMedia)"
                              :key="currentMedia.uid"/>
                        </div>
                        <img :src="currentMedia.link" class="w-100" v-if="currentMedia.media_type === 'image'"/>
                        <div class="file-wrap" v-if="currentMedia.media_type === 'other'">
                          <i class="mdi mdi-file-pdf" v-if="currentMedia.extension === 'pdf'"></i>
                          <i class="fe fe-file" v-if="currentMedia.extension !== 'pdf'"></i>
                        </div>
                      </div>
                      <div class="mt-1" v-if="currentMedia">
                        Tên file: <span class="">{{ currentMedia.name }}</span><br>
                        Kích cỡ: <span class="">{{ Math.round(currentMedia.size / 1024) }} KB</span><br>
                        Người up: <span class="">{{ currentMedia.user ? currentMedia.user.name : ''}}</span><br>
                        Ngày up: <span class="">{{ currentMedia.created_at }}</span><br>
                        <button class="btn btn-danger btn-xs mt-2" v-on:click="openConfirmDeleteMedia">Xóa</button>
                        <div class="d-inline-flex me-4 mt-3" v-if="showCourseware === true">
                          <div class="form-check me-3">
                            <input type="radio" id="isCourseWare0"
                                   v-model="currentMedia.attachmentPivot.is_courseware"
                                   value=0
                                   class="form-check-input">
                            <label class="form-check-label" for="isCourseWare0"><small class="text-nowrap">Bài giảng</small></label>
                          </div>
                          <div class="form-check me-3">
                            <input type="radio" id="isCourseWare1"
                                   v-model="currentMedia.attachmentPivot.is_courseware"
                                   value=1
                                   class="form-check-input">
                            <label class="form-check-label" for="isCourseWare1"><small class="text-nowrap">Học liệu</small></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button v-if="currentMedia" data-bs-dismiss="modal" type="button"
                    class="btn btn-primary"
                    v-on:click="setMedia">Sử dụng media
            </button>
          </div>
        </div>
      </div>
    </div>
    <button id="mediaModalOpen" class="d-none" data-bs-target="#mediaModal" data-bs-toggle="modal"></button>
  </div>
</template>

<style>
.video-box {
  width: 100% !important;
  height: auto !important;
}

.video-wrap video {
  width: 100% !important;
  height: 80px;
  z-index: 1;
}

.file-wrap {
  font-size: 78px;
}

#media .media-item img {
  object-fit: cover;
  width: 100%;
  height: 80px;
}

#media .media-item:hover {
  cursor: pointer;
}
</style>

<script>
import ApiService from "@/core/services/api.service";
import {cutString, getWhereCondition, openModal} from "@/core/services/utils.service";
import Upload from "@/components/Upload";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Player from "@/components/Player";

export default {
  name: "Media",
  components: {
    Upload,
    VPagination,
    Player
  },
  props: {
    mediaMode: String,
    purpose: String,
    mediaType: {
      type: String,
      default: ""
    },
    showCourseware: Boolean
  },
  data() {
    return {
      mediaFilters: {
        name: "",
        media_type: ""
      },
      currentMedia: null,
      media: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      purpose_: "",
      editorId: ""
    }
  },
  mounted() {
    this.loadMedia();
  },
  methods: {
    getWhereCondition() {
      let filters = {};

      for (let key in this.mediaFilters) {
        if (key === "name") {
          if (this.mediaFilters[key] !== "") {
            filters[key] = {
              operator: "LIKE",
              value: "%" + this.mediaFilters[key] + "%"
            }
          }
        } else {
          if (this.mediaFilters[key] !== "") {
            filters[key] = {
              operator: "EQ",
              value: this.mediaFilters[key]
            }
          }
        }
      }

      return getWhereCondition(filters);
    },
    loadMedia() {
      let where = this.getWhereCondition();

      let query = `query {
        media(orderBy: {column: "created_at", order: DESC}, page: ${this.media.paginatorInfo.currentPage}, first:60` + where + `) {
          data {
            id
            name
            size
            uid
            link
            hls
            server_type
            media_type
            extension
            created_at
            user {
              name
              thumbnail {
                link
              }
            }
          }
          paginatorInfo {
            lastPage
            currentPage
            total
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.media) {
              this.media = data.data.media;
              if (this.media.data.length) {
                this.media.data.map((media) => {
                  media.attachmentPivot = {
                    is_courseware: 0
                  }
                });
              }
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    setCurrentMedia(media) {
      this.currentMedia = media;
    },
    filterMediaByType(type) {
      this.mediaFilters.media_type = type;
    },
    setMedia() {
      this.$emit('setMedia', this.currentMedia, this.purpose_);

      window.postMessage({action: "setMedia", editorId: this.editorId, purpose: this.purpose_, data: JSON.parse(JSON.stringify(this.currentMedia))},
          window.location.origin);
    },
    getItemClasses(type) {
      let classes = "list-group-item list-group-item-action";

      if (type === this.mediaFilters.media_type) {
        classes = classes + " active";
      }

      return classes;
    },
    cutString(str, len) {
      return cutString(str, len);
    },
    handleUploadFinished(media) {
      if (typeof media === "object") {
        media.attachmentPivot = {
          is_courseware: 0
        }
        this.media.data.unshift(media);
        this.currentMedia = media;
      }
    },
    deleteMedia() {
      let query = `mutation($id: ID!) {
        deleteMedia(id: $id) {
          id
        }
      }`;

      ApiService.graphql(query, {id: this.currentMedia.id})
          .then(({data}) => {
            if (data.data && data.data.deleteMedia) {
              this.currentMedia = null;
              this.loadMedia();
              alert("Xóa media thành công");
            } else {
              alert(data.errors[0].message);
            }
          })
          .catch((response) => {
            alert(response.message);
          });
    },
    openConfirmDeleteMedia() {
      if (confirm("Bạn chắc chắn muốn xóa media này?")) {
        this.deleteMedia();
      }
    },
    getPlayerOptions(media) {
      return {
        autoplay: false,
        loop: false,
        controls: true,
        fill: true,
        sources: [
          {
            src: media.hls,
            type: "application/x-mpegURL"
          },
          {
            src: media.link,
            type: "video/mp4"
          },
          {
            src: media.link,
            type: "audio/mpeg"
          }
        ]
      }
    }
  },
  created() {
    this.mediaFilters.media_type = this.mediaType;
    this.purpose_ = this.purpose;

    window.addEventListener("message", (event) => {
      console.log(event);
      if (event.data.action === "openMedia") {
        this.purpose_ = "insertEditor";
        this.editorId = event.data.editor_id;
        openModal("mediaModal");
      }
      if (event.data.action === "setPurpose") {
        this.purpose_ = event.data.purpose;
      }
    }, false);
  },
  watch: {
    mediaType: {
      handler(newVal) {
        this.mediaFilters.media_type = newVal;
      }
    },
    mediaFilters: {
      handler() {
        this.media.paginatorInfo.currentPage = 1;
        this.loadMedia();
      },
      deep: true
    },
    purpose: {
      handler(newVal) {
        this.purpose_ = newVal;
      }
    },
  }
}
</script>
