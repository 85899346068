import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import mediaIcon from '@ckeditor/ckeditor5-ckfinder/theme/icons/browse-files.svg';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

class MediaPlugin extends Plugin {
  init() {
    const editor = this.editor;

    window.addEventListener("message", (event) => {
      if (event.data.action === "setMedia" && event.data.purpose === "insertEditor" && event.data.editorId === editor.id) {
        editor.model.change(writer => {
          if (event.data.data.media_type === "image") {
            const imageElement = writer.createElement('imageBlock', {
              src: event.data.data.link
            });

            editor.model.insertContent(imageElement, editor.model.document.selection);
          } else if (event.data.data.media_type === "video" || event.data.data.media_type === "audio") {
            console.log(event.data.data.uid);
            editor.execute( 'mediaEmbed', `https://erobo.vn/embed/${event.data.data.uid}`);
          }
        });
      }
    }, false);

    editor.ui.componentFactory.add('mediaPlugin', locale => {
      const view = new ButtonView(locale);

      view.set({
        label: 'Chèn video, hình ảnh',
        icon: mediaIcon,
        tooltip: true
      });

      view.on('execute', () => {
        window.postMessage({action: "openMedia", editor_id: editor.id},
          window.location.origin);
      });

      return view;
    });
  }
}

export default MediaPlugin;
