<template>
  <div>
    <div class="thumbnail">
      <a href="#" data-bs-target="#mediaModal" data-bs-toggle="modal" v-on:click="setThumbnail">
        <img :src="thumbnail && thumbnail.link ? thumbnail.link : '/assets/images/course/course-angular.jpg'" class="w-100"/>
      </a>
    </div>
    <div class="d-grid gap-2 mt-3">
      <button v-on:click="setThumbnail" type="button" class="btn btn-primary"
              data-bs-target="#mediaModal" data-bs-toggle="modal" data-placement="top"><i
          class="fe fe-plus-circle"></i> Ảnh đại diện
      </button>
    </div>
  </div>
</template>

<style>
.thumbnail img {
  object-fit: cover;
  width: 100%;
  height: 250px;
}

.thumbnail video {
  width: 100% !important;
  height: 100% !important;
}
</style>

<script>
export default {
  name: "Thumbnail",
  props: {
    thumbnail: Object,
    purpose: String
  },
  data() {
    return {

    }
  },
  methods: {
    setThumbnail() {
      window.postMessage({action: "setPurpose", purpose: this.purpose},
          window.location.origin);
    }
  }
}
</script>
