<template>
  <ckeditor :editor="editor" v-model="editorContent" :config="editorConfig"></ckeditor>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading';
import TablePlugin from '@ckeditor/ckeditor5-table/src/table';
import MediaPlugin from "@/components/MediaPlugin";
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import ListPlugin from '@ckeditor/ckeditor5-list/src/list';

export default {
  name: "Editor",
  props: {
    content: String
  },
  components: {
    ckeditor: CKEditor.component
  },
  data() {
    return {
      editor: ClassicEditor,
      editorContent: "",
      editorConfig: {
        extraPlugins: [MediaPlugin,],
        plugins: [
          EssentialsPlugin,
          BoldPlugin,
          ItalicPlugin,
          LinkPlugin,
          ParagraphPlugin,
          HeadingPlugin,
          Image,
          ImageToolbar,
          ImageStyle,
          ImageResize,
          LinkImage,
          MediaEmbed,
          TablePlugin,
          Alignment,
          ListPlugin,
          ImageCaption
        ],
        image: {
          toolbar: [ 'toggleImageCaption', 'imageTextAlternative', 'imageStyle:block', 'imageStyle:side', 'linkImage' ]
        },
        toolbar: {
          items: [
            'heading',
            '|',
            'bulletedList', 'numberedList', 'alignment',
            'bold',
            'italic',
            'link',
            'insertTable',
            'mediaEmbed',
            'mediaPlugin'
          ],
        },
        mediaEmbed: {
          extraProviders: [
            {
              name: 'erobo',
              url: /^erobo\.vn\/embed\/(\w+)/,
              html: match => {
                const uid = match[ 1 ];

                return (
                    '<div style="position: relative; padding-bottom: 100%; height: 0; ">' +
                    `<iframe src="${window.location.origin}/embed/${uid}" ` +
                    'style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" ' +
                    'frameborder="0" width="480" height="270" allowfullscreen allow="autoplay">' +
                    '</iframe>' +
                    '</div>'
                );
              }
            }
          ]
        }
      }
    }
  },
  watch: {
    editorContent: {
      handler(newVal) {
        this.$emit('update:content', newVal)
      }
    },
    content: {
      handler(newVal) {
        this.editorContent = newVal;
      }
    }
  },
  created() {
    this.editorContent = this.content;
  },
  mounted() {

  }
}
</script>
